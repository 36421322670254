import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'

const Description = styled.p`
  margin-bottom: 2em;
`

const PostTemplate = ({ data, pageContext }) => {
  const {
    name,
    translations: {
      ru: { description },
    },
    iframe,
    create_date: createDate,
    category,
  } = data.pacoGamesJson
  const postNode = data.pacoGamesJson

  const previous = pageContext.prev
  const next = pageContext.next

  return (
    <Layout>
      <Helmet>
        <title>{`${name} - ${config.siteTitle}`}</title>
      </Helmet>
      {/* <SEO pagePath={slug} postNode={postNode} postSEO /> */}

      {/* <Hero title={name} image={thumb3} height={'50vh'} /> */}

      <Container>
        <Description>{description}</Description>
        {/* {<TagList tags={[{ id: category, slug: category, title: category }]} />} */}
        {/* <PostDetails date={createDate} timeToRead={''} /> */}
        <PageBody body={iframe} />
      </Container>
      {/* <PostLinks previous={previous} next={next} /> */}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    pacoGamesJson(fields: { slug: { eq: $slug } }) {
      name
      fields {
        slug
      }
      date
      image
      video {
        mp4
      }
      id
      iframe
      rating
      translations {
        ru {
          description
        }
      }
    }
  }
`

export default PostTemplate
